import React, { useMemo } from 'react';
import styled from 'styled-components';

import '@reach/accordion/styles.css';
import { ifProp } from 'styled-tools';
import { BaseFilter } from './BaseFilter';

import { TagCreateModal } from './TagCreateModal';
import { Pencil } from '../Icons';

function TagFilter({
  className,
  data = [],
  selectedIds = [],
  query,
  memoQuery,
  onSelect,
  onSearch,
  onSelectAll,
  projectName,
  isAddTagModal,
  onAddTagModal,
  onCloseTagModal,
  onSubmit,
  onEditTagModal,
  tagMode,
  selectedTag,
  isLoading,
}) {
  const options = useMemo(
    () =>
      data
        .map(item => {
          const isChecked = selectedIds.includes(item.id);

          return {
            rank: item.name?.charCodeAt(0) + isChecked ? 100 : 0,
            onSelect(e) {
              e.stopPropagation();

              onSelect(item.id);
            },
            isChecked,
            id: item.id,
            name: item.name,
            color: item.color,
            isShow:
              isChecked ||
              !memoQuery ||
              item.name
                ?.toLowerCase()
                .startsWith(memoQuery.toLocaleLowerCase()),
          };
        })
        .sort((x, y) => y.rank - x.rank),
    [data, selectedIds, memoQuery, onSelect]
  );

  return (
    <BaseFilter
      className={className}
      title="Tags"
      query={query}
      onSearch={onSearch}
      onSelectAll={onSelectAll}
      onAdd={onAddTagModal}
      addPermission="project-tag:write"
      noSearch={options.length === 0}
      noSelectAll
      isLoading={isLoading}
    >
      <>
        {options.map(item => (
          <Tag
            checked={item.isChecked}
            {...(item.isShow && { show: 'true' })}
            key={item.id}
            onClick={item.onSelect}
            color={item.color}
            onEditTag={onEditTagModal}
            tag={item}
          >
            {item.name}
          </Tag>
        ))}
        <TagCreateModal
          isOpen={isAddTagModal}
          onClose={onCloseTagModal}
          onSubmit={onSubmit}
          projectName={projectName}
          tagMode={tagMode}
          selectedTag={selectedTag}
        />
      </>
    </BaseFilter>
  );
}

const Tag = styled(
  ({ className, children, onEditTag, tag, onClick, ...props }) => {
    const handleEditTag = () => {
      onEditTag(tag);
    };

    const handleOnClick = event => {
      if (event.target.id === 'action-tag') {
        handleEditTag();
        return;
      }
      onClick(event);
    };

    return (
      <button
        type="button"
        className={className}
        onClick={handleOnClick}
        {...props}
      >
        <div />
        {children}
        <div className="wrapper-pencil" id="action-tag">
          <Pencil size={8} />
        </div>
      </button>
    );
  }
)`
  display: ${ifProp('show', 'flex', 'none')};
  margin-right: 12px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: 1px solid
    ${ifProp('checked', props => (props.color ? '#fff' : '#999'), '#999')};
  padding: 2px 8px;

  color: ${ifProp('checked', props => (props.color ? '#fff' : '#999'), '#999')};
  font-size: 12px;
  font-weight: bold;

  background: ${ifProp(
    'checked',
    props => (props.color ? props.color : '#fff'),
    '#fff'
  )};

  transition: all 0.2s;

  > .wrapper-pencil {
    border-radius: 50%;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: end;
    margin-top: 3px;
    opacity: 1;

    ${Pencil} {
      display: none;
    }
  }

  &:hover {
    > .wrapper-pencil {
      ${Pencil} {
        display: block;
      }
    }
  }
`;

const StyledTagFilter = styled(TagFilter)`
  .filter-list {
    padding-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
  }

  .action-tags {
    font-size: 0.75rem;
    color: #9fa3ae;
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 10px;
  }
`;

const MemoTagFilter = React.memo(StyledTagFilter);

export { MemoTagFilter as TagFilter };
