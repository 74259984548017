import React, { useRef } from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import { SearchInput } from './SearchInput';
import { useDropdown } from '../../lib/hooks';
import { ArrowUpIcon } from '../Icons';

export const SearchSelect = React.memo(styled(function SearchSelect({
  handleSearch,
  onChange,
  options,
  className,
  search,
  placeholder,
  isFlag,
  name,
  selected,
}) {
  const { dropdownRef, isShowDropdown, toggleDropdown } = useDropdown();
  const inputRef = useRef(null);

  return (
    <div ref={dropdownRef} className={className}>
      <div
        className={`select-search ${isShowDropdown ? 'active' : ''}`}
        onClick={toggleDropdown}
      >
        <SearchInput
          value={!isFlag ? name : search}
          ref={inputRef}
          placeholder={placeholder}
          onChange={e => handleSearch(e.target.value)}
        />
        <ArrowUpIcon className="icon" width="16" height="16" />
      </div>

      {isShowDropdown && (
        <div ref={dropdownRef} className="select-search-list">
          {options &&
            options.map((option, index) => (
              <div
                className="option"
                key={index}
                onClick={() => onChange && onChange(option.id)}
              >
                <p
                  className={`message ${
                    selected === option.id ? 'active' : ''
                  }`}
                >
                  {option.name}
                </p>
              </div>
            ))}
        </div>
      )}
    </div>
  );
})`
  position: relative;

  .select-search {
    display: flex;
    min-height: 40px;
    cursor: pointer;
    border-radius: 8px;
    padding: 10px 8px;
    border: solid 1px #dce0ea;
    align-items: center;

    &.active {
      border-color: ${theme('colors.primary')};
    }

    .icon {
      transform: rotate(180deg);
      width: 11px;
      height: 11px;
    }
  }

  .select-search-list {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 10;
    margin-top: 8px;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    background: white;
    box-shadow: 0 5px 10px 0 rgba(58, 58, 58, 0.05);

    .option {
      display: flex;
      overflow-y: auto;
      align-items: center;
      padding: 8px 20px;

      &:hover {
        color: ${theme('colors.primary')};
      }

      .message {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        cursor: pointer;

        &.active {
          color: ${theme('colors.primary')};
        }
      }
    }
  }
`);
