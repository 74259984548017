import React from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import { ArrowUpIcon } from '../Icons';
import { useMutiSelectDropDown } from '../../lib/hooks';
import { Checkbox } from './Checkbox';

function MultiSelect({
  className,
  options,
  label,
  value,
  placeholder,
  inputClassName,
  onChange,
  ...props
}) {
  const {
    dropdownRef,
    isShowDropdown,
    toggleDropdown,
  } = useMutiSelectDropDown();

  return (
    <div className={className}>
      {label && <div className="label">{label}</div>}
      <div
        className={`multi-select ${isShowDropdown ? 'active' : ''}`}
        onClick={toggleDropdown}
      >
        <div className="content-select">
          {value.length > 0 ? (
            <div className="select">
              <div className={`input-select ${inputClassName}`}>
                {value
                  .map(
                    val => options.find(option => option.value === val)?.label
                  )
                  .join(', ')}
              </div>
            </div>
          ) : (
            <div className="placeholder">{placeholder}</div>
          )}
        </div>
        <ArrowUpIcon className="icon" width="16" height="16" />
      </div>
      <div className="multi-select-list">
        <div
          ref={dropdownRef}
          className={`group ${!isShowDropdown ? 'hidden' : ''}`}
        >
          <div className="option-list">
            <div className="option title">All project</div>
            {options.map(option => (
              <div key={option.value} className="option">
                <Checkbox
                  className="checkbox"
                  {...props}
                  value={option.value}
                  label={option.label}
                  checked={value?.includes(option.value)}
                  onChange={() => onChange(option.value)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

const StyledMultiSelect = styled(MultiSelect)`
  .label {
    margin-bottom: 4px;
    font-size: 12px;
  }

  .multi-select {
    display: flex;
    min-height: 40px;
    cursor: pointer;
    border-radius: 8px;
    padding: 10px 8px;
    border: solid 1px #dce0ea;
    align-items: center;

    &.active {
      border-color: ${theme('colors.primary')};
    }

    .icon {
      transform: rotate(180deg);
      width: 11px;
      height: 11px;
    }

    .content-select {
      display: flex;
      flex: 1 1 0%;
      flex-wrap: wrap;
      gap: 6px;

      .select {
        display: flex;
        align-items: center;
        gap: 5px;
        border-radius: 8px;

        .input-select {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 130px;
        }
      }
    }
  }
  .multi-select-list {
    position: relative;

    .group {
      position: absolute;
      top: 5px;
      z-index: 10;
      max-height: 200px;
      width: 100%;
      overflow-y: auto;
      border-radius: 0.25rem;
      background: white;
      box-shadow: 0 5px 10px 0 rgba(58, 58, 58, 0.05);

      .option-list {
        width: 100%;

        .option {
          display: flex;
          overflow-y: auto;
          align-items: center;
          padding: 6px 20px;

          &.title {
            border-bottom: solid 1px #dce0ea;
            margin: 0 20px;
            padding: 6px 0;
            margin-top: 20px;
            margin-bottom: 10px;
            padding-bottom: 10px;
          }
        }
      }
    }
    .hidden {
      display: none;
    }
  }
`;

export { StyledMultiSelect as MultiSelect };
