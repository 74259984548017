import React, { useState, useContext, useMemo } from 'react';
import useSWR from 'swr';
import hoist from 'hoist-non-react-statics';
import { useParams } from 'react-router-dom';
import { useQueryParams } from '../../lib/hooks';
import { ToolingsClient } from '../../clients/ToolingsClient';
import GlobalContext from '../../store/globalContext';

export function withSearchSelect(Component) {
  function WithSearchSelect({ ...props }) {
    const [queryParams, setQueryParams] = useQueryParams();
    const [search, setSearch] = useState('');
    const [isFlag, setIsFlag] = useState(false);
    const { companyId } = useParams();
    const projectId = queryParams.projectIds || null;

    const isProjectSelect = useMemo(() => {
      const projectIds = projectId?.split(',');

      return projectIds && projectIds.length === 1;
    }, [projectId]);

    const { data: members, profileId } = useMemberOptions({
      companyId,
      query: search,
      isProjectSelect,
    });

    function handleChange(value) {
      setSearch('');
      setIsFlag(false);

      setQueryParams({ memberId: value });
    }

    const selected = !isProjectSelect
      ? queryParams.memberId || profileId
      : queryParams.memberId || null;

    const name = useMemo(() => {
      return members.find(opt => opt.id === selected)?.name || 'All Members';
    }, [members, selected]);

    function handleSearch(query) {
      setSearch(query);
      setIsFlag(true);

      if (query === '') {
        setQueryParams({ memberId: null });
      }
    }

    const pageProps = {
      isFlag,
      options: members,
      search,
      handleSearch,
      onChange: handleChange,
      selected,
      name,
      ...props,
    };

    return <Component {...pageProps} />;
  }

  hoist(WithSearchSelect, Component);

  WithSearchSelect.displayName = `withSearchSelect(${
    Component.displayName ?? Component.name ?? 'Component'
  })`;

  return WithSearchSelect;
}

function useMemberOptions({
  companyId,
  status = 'active',
  query,
  isProjectSelect,
}) {
  const client = new ToolingsClient();
  const { globalState } = useContext(GlobalContext);
  const { profile } = globalState;

  const { data } = useSWR(
    ['members', query],
    () => client.listCompanyMembers({ companyId, status, query }),
    { revalidateOnFocus: false }
  );

  const newData = transformMemberOptions(data?.data, profile) || [];

  if (isProjectSelect) {
    return {
      data: [{ id: null, name: 'All Member' }, ...newData],
      profileId: profile?.id,
    };
  }
  return { data: [...newData], profileId: profile?.id };
}

function transformMemberOptions(members, profile) {
  const me = (members || []).find(member => member?.id === profile?.id);

  const memberList = (members || [])
    .filter(member => member?.id !== profile?.id)
    .map(item => ({
      name: `${item?.nickName ? `[${item?.nickName}]` : ''} ${item?.fullName}`,
      ...item,
    }));

  if (me) {
    memberList.unshift({ id: me?.id, name: `${me?.fullName} (me)` });
  }
  return memberList || [];
}
