import { Droppable } from 'react-beautiful-dnd';
import React from 'react';
import styled from 'styled-components';
import hoistNonReactStatics from 'hoist-non-react-statics/src';
import { DraggableTicket } from './Ticket';
import { PlusIcon } from '../Icons';

class InnerTicket extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (
      nextProps?.queryParams?.sortTicketNoAsc !==
        this.props.queryParams?.sortTicketNoAsc ||
      nextProps?.queryParams?.sortTicketNoDesc !==
        this.props.queryParams?.sortTicketNoDesc ||
      nextProps?.queryParams?.sortDateAsc !==
        this.props.queryParams?.sortDateAsc ||
      nextProps?.queryParams?.sortDateDesc !==
        this.props.queryParams?.sortDateDesc
    ) {
      return true;
    }

    if (
      nextProps?.queryParams?.sortTicketNoDesc !==
      this.props.queryParams?.sortTicketNoDesc
    ) {
      return true;
    }

    if (nextProps.tickets === this.props.tickets) {
      return false;
    }

    return true;
  }

  render() {
    const self = this.props;
    const id = self.listId;
    const { tickets } = self;
    const { queryParams } = self;
    const sortDateAsc = queryParams?.sortDateAsc?.split(',') ?? [];
    const sortDateDesc = queryParams?.sortDateDesc?.split(',') ?? [];
    const sortTicketNoAsc = queryParams?.sortTicketNoAsc?.split(',') ?? [];
    const sortTicketNoDesc = queryParams?.sortTicketNoDesc?.split(',') ?? [];

    if (sortDateAsc.includes(String(id))) {
      tickets.sort((a, b) => {
        const previousMin = a.dueDate
          ? a.dueDate.toSeconds() / 60 +
            (a.startDate?.hour ?? 0) * 60 +
            (a.startDate?.minute ?? 0)
          : Infinity;
        const nextMin = b.dueDate
          ? b.dueDate.toSeconds() / 60 +
            (b.startDate?.hour ?? 0) * 60 +
            (b.startDate?.minute ?? 0)
          : Infinity;
        return previousMin - nextMin;
      });
    } else if (sortDateDesc.includes(String(id))) {
      tickets.sort((a, b) => {
        const previousMin = b.dueDate
          ? b.dueDate.toSeconds() / 60 +
            (b.startDate?.hour ?? 0) * 60 +
            (b.startDate?.minute ?? 0)
          : -Infinity;
        const nextMin = a.dueDate
          ? a.dueDate.toSeconds() / 60 +
            (a.startDate?.hour ?? 0) * 60 +
            (a.startDate?.minute ?? 0)
          : -Infinity;
        return previousMin - nextMin;
      });
    } else if (sortTicketNoAsc.includes(String(id))) {
      tickets.sort((a, b) => {
        return a.idEachGroup - b.idEachGroup;
      });
    } else if (sortTicketNoDesc.includes(String(id))) {
      tickets.sort((a, b) => {
        return b.idEachGroup - a.idEachGroup;
      });
    } else {
      tickets.sort((a, b) => {
        return a.order - b.order;
      });
    }

    return tickets?.map((ticket, index) => (
      <DraggableTicket
        onClick={() =>
          self.onSelectTicket
            ? self.onSelectTicket(
                ticket.id,
                ticket.idEachGroup,
                self.project.id
              )
            : null
        }
        key={`ticket-${ticket.id}`}
        index={index}
        color={ticket?.epicColor || self.project?.color || '#22c759'}
        projectId={self.project?.id}
        {...ticket}
      />
    ));
  }
}

const TicketList = React.forwardRef(function TicketList(
  {
    project,
    data,
    listId,
    onSelectTicket,
    placeholder,
    onAddTicket,
    queryParams,
    ...props
  },
  ref
) {
  return (
    <div {...props} ref={ref}>
      {onAddTicket && (
        <div className="add-button" onClick={onAddTicket}>
          <PlusIcon /> Add Ticket
        </div>
      )}
      <InnerTicket
        queryParams={queryParams}
        tickets={data}
        listId={listId}
        onSelectTicket={onSelectTicket}
        project={project}
      />
      {placeholder}
    </div>
  );
});

const StyledTicketList = styled(TicketList)`
  > .add-button {
    background: #eee;
    text-align: center;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 6px;
    cursor: pointer;
  }
`;

const MemoTicketList = React.memo(StyledTicketList);

function DroppableTicketList({ listId, ...props }) {
  return (
    <Droppable droppableId={listId} type="TICKET">
      {provided => (
        <MemoTicketList
          {...provided.droppableProps}
          ref={provided.innerRef}
          placeholder={provided.placeholder}
          listId={listId}
          {...props}
        />
      )}
    </Droppable>
  );
}

hoistNonReactStatics(DroppableTicketList, StyledTicketList);

export { MemoTicketList as TicketList, DroppableTicketList };
